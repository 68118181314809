/* background image styling */
.backgroundImageContainer {
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    
}

.backgroundImage {
	top: 50%;
	left: 50%;
    z-index: 0;
	min-width: 100%;
	min-height: 100%;
    position: absolute;
	transform: translateX(-50%) translateY(-50%);
}

/* event info styling */
.eventTimeInfoContainer {
    gap: 2rem;
    width: 100%;
    display: flex;
    min-height: 100vh;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.eventTimeInfoContainer > span {
    color: white;
    font-size: 65px;
    font-weight: 1000;
    text-shadow: 1px 1px black;
}

/* lobby styling */
.lobbyContainer {
    gap: 2rem;
    width: 100%;
    display: flex;
    min-height: 100vh;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.lobbyContainer > span {
    color: white;
    font-size: 65px;
    font-weight: 1000;
    text-shadow: 1px 1px black;
}

.lobbyContainer > button {
    width: 150px;
    height: 50px;
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    background-color: #efcc1f;
    text-shadow: 1px 1px black;
    border: 0px solid transparent;
    border-radius: 5px 25px 5px 25px;
    font-family: 'Rajdhani', sans-serif;
}

/* main game container styling */
.gameContainer {
    width: 100%;
    display: flex;
    min-height: 100vh;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.statsContainer {
    top: 20vh;
    gap: 3rem;
    display: flex;
    color: white;
    font-size: 24px;
    flex-wrap: wrap;
    font-weight: 1000;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px black;
}

.statsContainer > div {
    height: 100px;
    width: 240px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 3px solid rgba(0, 60, 255, 0.438);
    background-color: rgba(0, 153, 255, 0.5);
    /* background-o */
}

.statsContainer > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.statsContainer > div > div > span {
    margin-left: 45px;
    margin-right: 10px;
}

.tokenImg {
    height: 40px;
    width: 40px;
}

/* .statsContainer > div:after{
    content: '';
    width: 300px;
    height: 300px;
    background: inherit; 
    box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05);
    filter: blur(10px);
} */

.decisionsContainer {
    
}

.decisionTimerContainer {
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.decisionTimer{
    color: white;
    font-size: 68px;
    max-width: 300px;
    font-weight: 1000;
    text-shadow: 2px 2px black;
}

.decisionTimerContainer > span {
    color: red;
    font-weight: 600;
    text-shadow: 1px 1px black;
}

.decisionTimerMsg {
    font-size: 24px;
    text-align: center;
}

.decisionBtnsContainer {
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.decisionBtnsContainer > span {
    color: white;
    font-size: 24px;
    font-weight: 1000;
    text-shadow: 1px 1px black;
}

.decisionBtn {
    width: 150px;
    height: 50px;
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    text-shadow: 1px 1px black;
    border: 0px solid transparent;
    border-radius: 5px 25px 5px 25px;
    font-family: 'Rajdhani', sans-serif;
}

.stealDecisionBtn {
    background-color: green;
}

.splitDecisionBtn {
    background-color: green;
}

.decisionBtn:hover {
    font-weight: 600;
    border: 2px solid white;
    transform: translateY(-5px);
    transition: transform 0.5s;
    box-shadow: 3px 3px black;
}

@media all and (max-width: 360px) {
    .decisionBtnsContainer {
        flex-direction: column;
    }

    .decisionTimer {
        font-size: 60px;
    }

    
}

@media all and (max-width: 360px) {
    .decisionBtnsContainer {
        gap: 0.1rem;
    }
}

@media all and (max-width: 450px) and (min-width: 360px) {
    .statsContainer {
        gap: .1rem;
        top: 15vh;
        font-size: 22px;
    }
    
    .decisionTimerContainer {
        height: 250px;
        margin-top: 350px;
    }
}

@media all and (max-width: 510px) and (min-width: 450px) {
    .statsContainer {
        gap: .5rem;
        top: 15vh;
    }

    .decisionTimerContainer {
        height: 250px;
        margin-top: 350px;
    }
}

@media all and (max-width: 840px) and (min-width: 510px) {
    .statsContainer {
        gap: 1rem;
        top: 20vh;
        font-size: 22px;
    }
    
    .decisionTimerContainer {
        height: 250px;
        margin-top: 150px;
    }
}


.afterDecision {
    display: none;
    color: white;
    font-size: 38px;
    font-weight: 1000;
    text-align: center;
    text-shadow: 1px 1px black;
}

.afterDecisionSplit {
    color: green;
    font-size: 46px;
    margin-left: 8px;
    text-transform: uppercase;
}

.afterDecisionSteal {
    color: green;
    font-size: 46px;
    margin-left: 8px;
    text-transform: uppercase;
}

.notInGame {
    display: flex;
    height: 100vh;
    color: white;
    font-size: 38px;
    font-weight: 500;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px black;
}

.winningContainer {
    gap: 2rem;
    display: none;
    height: 100vh;
    color: white;
    font-size: 38px;
    font-weight: 500;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-shadow: 1px 1px black;
}

.winningContainer > .winningBalance {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.loosingContainer {
    gap: 2rem;
    display: none;
    height: 100vh;
    color: white;
    font-size: 38px;
    font-weight: 500;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-shadow: 1px 1px black;
}

.stayOrLeaveContainer {
    gap: 6rem;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.stayOrLeaveStats {
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.stayOrLeaveStats > .winningStats {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.stayOrLeaveContainer > div > span {
    color: white;
    font-size: 38px;
    font-weight: 500;
    text-align: center;
    text-shadow: 1px 1px black;
}

.stayOrLeaveContainer > .slDecisionBtnsContainer {
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.stayOrLeaveContainer > .slDecisionBtnsContainer > span {
    font-size: 28px;
}

.slDecisionBtn {
    height: 50px;
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    text-shadow: 1px 1px black;
    border: 0px solid transparent;
    border-radius: 10px;
    width: 120px;
    font-family: 'Rajdhani', sans-serif;
}

.stayDecisionBtn {
    background-color: green;
}

.leaveDecisionBtn {
    background-color: red;
}

.afterSLDecision {
    display: none;
    color: white;
    font-size: 34px;
    font-weight: 1000;
    text-align: center;
    text-shadow: 1px 1px black;
}

.afterSLDecisionStay {
    color: green;
    font-size: 43px;
    margin-left: 8px;
    text-transform: uppercase;
}

.afterSLDecisionLeave {
    color: red;
    font-size: 43px;
    margin-left: 8px;
    text-transform: uppercase;
}

.slDecisionBtn:hover {
    font-weight: 600;
    border: 2px solid white;
    transform: translateY(-5px);
    transition: transform 0.5s;
    box-shadow: 3px 3px black;
}

.leaveContainer {
    gap: 2rem;
    display: flex;
    height: 100vh;
    color: white;
    font-size: 38px;
    font-weight: 500;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-shadow: 1px 1px black;
}

.leaveContainer > .leaveBalance {
    gap: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toNextRoundContainer {
    gap: 2rem;
    display: flex;
    height: 100vh;
    color: white;
    font-size: 38px;
    font-weight: 500;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-shadow: 1px 1px black;
}

.toNextRoundContainer > .nextRoundBalance { 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}