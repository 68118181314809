.LobbyContainer {
    gap: 2rem;
    z-index: 1;
    display: flex;
    height: 100vh;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.lobbyPlayersCount {
    color: white;
    font-size: 54px;
    font-weight: 500;
    text-shadow: 1px 1px black;
}


.joinedText {
    color: white;
    font-size: 42px;
    font-weight: 500;
    text-shadow: 1px 1px black;
}

.lobbyTimer {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 48px;
    font-weight: 500;
    text-shadow: 1px 1px black;
}

.joinLobbyBtn {
    width: 150px;
    height: 50px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    background-color: #efcc1f;
    border: 0px solid transparent;
    border-radius: 5px 25px 5px 25px;
    font-family: 'Rajdhani', sans-serif;
}

.joinLobbyBtn:hover {
    font-weight: 600;
    border: 2px solid white;
    transform: translateX(5px);
    transition: transform 0.5s;
    box-shadow: 3px 3px black;
}