.registrationContainer {
    gap: 2rem;
    z-index: 1;
    display: flex;
    height: 100vh;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.registerHeading {
    color: white;
    font-size: 50px;
    font-weight: 500;
    text-shadow: 1px 1px black;
}

.registerBtn {
    width: 150px;
    height: 50px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    background-color: #efcc1f;
    border: 0px solid transparent;
    border-radius: 5px 25px 5px 25px;
    font-family: 'Rajdhani', sans-serif;
}

.registerBtn:hover {
    font-weight: 600;
    border: 2px solid white;
    transform: translateX(5px);
    transition: transform 0.5s;
    box-shadow: 3px 3px black;
}

.eventHeading {
    color: white;
    font-size: 56px;
    font-weight: 500;
    text-shadow: 1px 1px black;
}

.eventTimer {
    color: white !important;
    font-size: 48px;
    font-weight: 500;
    text-shadow: 1px 1px black;
}