.mainContainer {
    
}

/* background image styling */
.backgroundImageContainer {
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}

.backgroundImage {
	top: 50%;
	left: 50%;
    z-index: 0;
	min-width: 100%;
	min-height: 100%;
    position: absolute;
	transform: translateX(-50%) translateY(-50%);
}

.infoMsg {
    display: flex;
    height: 100vh;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.infoMsg > span {
    color: white;
    font-size: 58px;
    font-weight: 500;
    text-shadow: 1px 1px black;
}

.lobbyTimer {
    font-size: 50px;
}