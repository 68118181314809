.navbar {
    z-index: 1;
    height: 80px;
    display: flex;
    padding: 20px;
    width: -webkit-fill-available;
    position: absolute;
    flex-direction: column;
    justify-content: center;
}

.connectWalletBtn {
    width: 150px;
    height: 50px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-end;
    background-color: #efcc1f;
    border: 0px solid transparent;
    border-radius: 5px 25px 5px 25px;
    font-family: 'Rajdhani', sans-serif;
}

.connectWalletBtn:hover {
    font-weight: 600;
    border: 2px solid white;
    transform: translateY(-5px);
    transition: transform 0.5s;
    box-shadow: 3px 3px black;
}

.accountConnected {
    color: black;
    max-width: 150px;
    font-weight: 800;
    overflow: hidden;
    align-self: flex-end;
    text-overflow: ellipsis;
}